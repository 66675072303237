import React from 'react';
import "./howtoguides.scss"
import SectionContact from "../../components/SectionContact";
import ImagePackage from "../../assets/howto/configureApackage.jpg";
import LiveTesting from "../../assets/howto/datascience-computer.png";
import TaskConf from "../../assets/howto/analysis-g229efeaa4_1920.jpg";
import OsirisR from "../../assets/howto/monitor showing Java programming.jpg";
import CardHowToGuide from "../../components/card/CardHowToGuide";
import {DocumentTypes} from "../../components/ButtonDocumentDownload";
import DivLanguage from "../../components/DivLanguage";
import LogoOsiris from "../../components/logo/LogoOsiris";


const HowToGuides = () => {

    return (
        <>
            <section className="top-section wave-section">
                <DivLanguage language="fr" className="center">
                    <div className="title">
                        <div className="line">
                            <h1>
                                Guides pratiques
                            </h1>
                            <div className="logo">
                                <LogoOsiris/>
                            </div>
                        </div>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <div className="title">
                        <div className="line">
                            <h1>
                                Practical guides
                            </h1>
                            <div className="logo">
                                <LogoOsiris/>
                            </div>
                        </div>
                    </div>
                </DivLanguage>

            </section>
            <section className={"how-to-guide-center-section wave-section"}>
                <DivLanguage language="fr" className="center wrapper-demi">
                    <CardHowToGuide image={ImagePackage}
                                    cardTitle={"Package"}
                                    cardSubTitle={"Comment configurer un package?"}
                                    documentPath={DocumentTypes.HOWTOCONFIGPACK}>

                        <p>
                            Dans ce guide, nous décrivons les principales options disponibles pour configurer
                            un package dans Osiris. Ces options de configuration permettent de traiter
                            une large variété de données.
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={TaskConf}
                                    cardTitle={"Osiris tasks"}
                                    cardSubTitle={"Comment configurer des tasks?"}
                                    documentPath={DocumentTypes.HOWTOCONFIGTASK}>

                        <p>
                            Dans ce guide, nous détaillons les étapes nécessaires à la création d'une "task" dans
                            Osiris. En suivant ces étapes, nous allons pouvoir automatiser différents processus :
                            calculs, recherche de données, rapports,...
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={LiveTesting}
                                    cardTitle={"Live testing"}
                                    cardSubTitle={"Comment utiliser le live testing?"}
                                    documentPath={DocumentTypes.HOWTOUSELIVETEST}>

                        <p>
                            Dans ce guide, nous montrons comment utiliser le live testing d'Osiris. Grâce à ce guide,
                            vous maîtriserez ce module permettant de mettre au point vos fonctions R ou Python pour
                            vos différents processus.
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={OsirisR}
                                    cardTitle={"OsirisR"}
                                    cardSubTitle={"Comment utiliser OsirisR?"}
                                    documentPath={DocumentTypes.HOWTOUSELIVETEST}>

                        <p>
                            Dans ce guide, nous revenons sur les principales fonctions du package OsirisR utilisable avec
                            R. Il vous permettra d'exploiter rapidement les fonctions usuelles du package OsirisR.

                        </p>

                    </CardHowToGuide>

                </DivLanguage>

                <DivLanguage language="en" className="center wrapper-demi">
                    <CardHowToGuide image={ImagePackage}
                                    cardTitle={"Package"}
                                    cardSubTitle={"How to configure a package?"}
                                    documentPath={DocumentTypes.HOWTOCONFIGPACK}>

                        <p>
                            In this guide, we describe the main options available for configuring a package in Osiris.
                            These configuration options allow for processing a wide variety of data.
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={TaskConf}
                                    cardTitle={"Osiris tasks"}
                                    cardSubTitle={"How to configure a task?"}
                                    documentPath={DocumentTypes.HOWTOCONFIGTASK}>

                        <p>
                            In this guide, we detail the steps necessary to create a 'task' in Osiris.
                            By following these steps, we will be able to automate various processes: calculations,
                            data retrieval, reports, etc.
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={LiveTesting}
                                    cardTitle={"Live testing"}
                                    cardSubTitle={"Comment utiliser le live testing?"}
                                    documentPath={DocumentTypes.HOWTOUSELIVETEST}>

                        <p>
                            In this guide, we show how to use Osiris's live testing.
                            With this guide, you will master this module, which allows you to develop
                            your R or Python functions for your various processes.
                        </p>

                    </CardHowToGuide>
                    <CardHowToGuide image={OsirisR}
                                    cardTitle={"OsirisR"}
                                    cardSubTitle={"How to use OsirisR?"}
                                    documentPath={DocumentTypes.HOWTOUSELIVETEST}>

                        <p>
                            In this guide, we revisit the main functions of the OsirisR package usable with R.
                            It will allow you to quickly utilize the common functions of the OsirisR package.

                        </p>

                    </CardHowToGuide>
                </DivLanguage>


            </section>


            <SectionContact className="white-wave"/>
        </>


    )
};

export default HowToGuides;