import React from 'react';
import BtnLinkCenter from "../../components/BtnLinkCenter";
import DivLanguage from "../../components/DivLanguage";
import CardWithBorder from "../../components/card/CardWithBorder";

const HomeMiddle = () => {
    return (
        <section className="home-products-services wave-section">

            <div className="center">
                <DivLanguage language="fr">

                    <div className="wrapper-demi">
                        <div className="title-n-description">
                            <h2>
                                Maitriser vos opérations financières et actuarielles
                            </h2>
                            <p className="p20-medium">
                                Osiris permet aux assureurs de gérer
                                leurs données actuarielles et financières.
                                Il simplifie la préparation des données ainsi
                                que l'analyse des résultats et
                                permet d'organiser des processus complexes de
                                façon simplifiée.
                            </p>
                        </div>
                        <CardWithBorder title="Installation rapide"
                                        icon="fa fa-wrench" className={"item"}>

                            <p className="p18-regular">

                                Disposez de vos environnements en quelques
                                minutes!


                            </p>


                        </CardWithBorder>
                    </div>


                    <div className="wrapper-demi">

                        <CardWithBorder title="Gagnez du temps"
                                        icon="fa fa-clock-o"
                                        className={"item"}>
                            <>
                                <p className="p18-regular">

                                    Utilisez votre temps pour l'analyse et la prise
                                    de décisions.


                                </p>


                            </>
                        </CardWithBorder>


                        <CardWithBorder title="Automatisez vos calculs"
                                        icon="fa fa-diagram-project"
                                        className={"item"}>
                            <>
                                <p className="p18-regular">
                                    Exécutez automatiquement vos tâches calculatoires
                                    les plus complexes
                                </p>


                            </>
                        </CardWithBorder>
                    </div>


                </DivLanguage>
                <DivLanguage language="en">

                    <div className="wrapper-demi">
                        <div className="title-n-description">
                            <h2>
                                Master Your Financial and Actuarial Operations
                            </h2>
                            <p className="p20-medium">
                                Osiris enables insurers to manage
                                their actuarial and financial data.
                                It simplifies data preparation as well
                                as results analysis and
                                allows for organizing complex processes
                                in a simplified manner.
                            </p>
                        </div>
                        <CardWithBorder title="Quick Installation"
                                        icon="fa fa-wrench" className={"item"}>
                            <>
                                <p className="p18-regular">
                                    Set up your environments in minutes!
                                </p>
                            </>
                        </CardWithBorder>
                    </div>

                    <div className="wrapper-demi">

                        <CardWithBorder title="Save Time"
                                        icon="fa fa-clock-o"
                                        className={"item"}>
                            <>
                                <p className="p18-regular">
                                    Use your time for analysis and decision-making.
                                </p>
                            </>
                        </CardWithBorder>

                        <CardWithBorder title="Automate Your Calculations"
                                        icon="fa fa-diagram-project"
                                        className={"item"}>
                            <>
                                <p className="p18-regular">
                                    Automatically execute your most complex calculation tasks.
                                </p>
                            </>
                        </CardWithBorder>
                    </div>

                </DivLanguage>


                <DivLanguage language="fr" className="center">
                    <BtnLinkCenter link="/osiris/why"
                                   value="Plus de détails sur Osiris"
                                   className="big-button txt-color-dark"/>
                </DivLanguage>

                <DivLanguage language="en" className="center">
                    <BtnLinkCenter link="/osiris/why"
                                   value="More details about Osiris"
                                   className="big-button txt-color-dark"/>
                </DivLanguage>


            </div>

        </section>
    )
};

export default HomeMiddle;