import React from 'react';
import PropTypes from "prop-types";

const LogoOsiris = ({colorTxt, colorVibe}) => {
    return (
        <svg width="223" height="129" viewBox="0 0 223 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_458_5928)">
                <g clipPath="url(#clip1_458_5928)">
                    <path d="M17.0102 55.94C21.1202 54.68 25.4902 54 30.0002 54C46.2602 54 60.5002 62.77 68.2602 75.82L78.4402 69.47C68.5502 53.03 50.5402 42 29.9902 42C24.3402 42 18.8802 42.84 13.7202 44.4L16.9902 55.94H17.0102Z" fill={colorVibe}/>
                    <path d="M11.2802 35.74C17.2202 33.97 23.5002 33 30.0002 33C53.7602 33 74.6002 45.72 86.0802 64.7L96.2602 58.35C82.6502 35.98 58.0402 21 29.9902 21C22.3502 21 14.9702 22.13 7.99023 24.2L11.2602 35.74H11.2802Z" fill={colorVibe}/>
                    <path d="M5.54002 15.53C13.3 13.24 21.5 12 30 12C61.26 12 88.7 28.67 103.9 53.59L114.08 47.24C96.76 18.93 65.55 0 30 0C20.37 0 11.07 1.39 2.27002 3.98L5.54002 15.53Z" fill={colorVibe}/>
                </g>
                <g clipPath="url(#clip2_458_5928)">
                    <path d="M60.5598 98.35C60.5598 101.11 60.1898 103.78 59.4598 106.36C58.7498 108.91 57.7498 111.3 56.4398 113.55C55.1298 115.77 53.5498 117.8 51.6998 119.65C49.8498 121.49 47.8098 123.08 45.5798 124.4C43.3498 125.7 40.9398 126.7 38.3498 127.41C35.7598 128.14 33.0798 128.51 30.3098 128.51C27.5398 128.51 24.8498 128.14 22.2698 127.41C19.7098 126.71 17.2998 125.7 15.0398 124.4C12.8098 123.07 10.7698 121.49 8.90977 119.65C7.05977 117.81 5.46977 115.78 4.12977 113.55C2.81977 111.3 1.79977 108.9 1.06977 106.36C0.359766 103.79 0.00976562 101.12 0.00976562 98.35C0.00976562 95.58 0.359766 92.92 1.06977 90.35C1.80977 87.78 2.82977 85.38 4.12977 83.16C5.45977 80.94 7.05977 78.91 8.90977 77.06C10.7598 75.22 12.7998 73.65 15.0398 72.35C17.2998 71.05 19.7098 70.05 22.2698 69.34C24.8598 68.61 27.5398 68.24 30.3098 68.24C33.0798 68.24 35.7698 68.61 38.3498 69.34C40.9398 70.04 43.3498 71.05 45.5798 72.35C47.8098 73.65 49.8498 75.22 51.6998 77.06C53.5498 78.9 55.1298 80.93 56.4398 83.16C57.7498 85.38 58.7498 87.78 59.4598 90.35C60.1998 92.92 60.5598 95.59 60.5598 98.35ZM48.9198 98.35C48.9198 95.8 48.4298 93.41 47.4498 91.16C46.4698 88.88 45.1398 86.92 43.4498 85.27C41.7898 83.59 39.8198 82.26 37.5298 81.29C35.2698 80.32 32.8598 79.83 30.2998 79.83C27.7398 79.83 25.2898 80.32 23.0298 81.29C20.7698 82.27 18.7998 83.59 17.1098 85.27C15.4198 86.92 14.0898 88.89 13.1098 91.16C12.1298 93.41 11.6398 95.81 11.6398 98.35C11.6398 100.89 12.1298 103.29 13.1098 105.54C14.0898 107.76 15.4198 109.71 17.1098 111.39C18.7998 113.07 20.7698 114.4 23.0298 115.37C25.2898 116.35 27.7098 116.83 30.2998 116.83C32.8898 116.83 35.2698 116.34 37.5298 115.37C39.8198 114.39 41.7898 113.07 43.4498 111.39C45.1398 109.71 46.4698 107.76 47.4498 105.54C48.4298 103.29 48.9198 100.89 48.9198 98.35Z" fill={colorTxt}/>
                    <path d="M91.1001 127.24H65.6201V116.19H91.1001C91.8601 116.19 92.5201 115.92 93.0601 115.38C93.6001 114.84 93.8801 114.19 93.8801 113.43C93.8801 112.67 93.6101 112.13 93.0601 111.89C92.5201 111.65 91.8601 111.52 91.1001 111.52H79.5001C77.5701 111.52 75.7601 111.15 74.0701 110.42C72.3801 109.69 70.9101 108.7 69.6601 107.45C68.4101 106.18 67.4101 104.7 66.6801 103.02C65.9701 101.34 65.6201 99.5401 65.6201 97.6201C65.6201 95.7001 65.9701 93.9001 66.6801 92.2201C67.4201 90.5401 68.4101 89.0801 69.6601 87.8301C70.9101 86.5801 72.3801 85.6101 74.0701 84.9101C75.7601 84.1801 77.5701 83.8101 79.5001 83.8101H102.08V94.8601H79.5001C78.7401 94.8601 78.0801 95.1301 77.5401 95.6701C77.0001 96.2101 76.7201 96.8601 76.7201 97.6201C76.7201 98.3801 76.9901 99.0801 77.5401 99.6501C78.0801 100.19 78.7401 100.46 79.5001 100.46H91.1001C93.0101 100.46 94.8001 100.78 96.4901 101.43C98.1801 102.05 99.6501 102.93 100.9 104.07C102.15 105.21 103.15 106.58 103.88 108.17C104.62 109.77 104.98 111.52 104.98 113.41C104.98 115.3 104.61 117.14 103.88 118.81C103.14 120.46 102.15 121.93 100.9 123.2C99.6501 124.45 98.1801 125.44 96.4901 126.17C94.8001 126.87 93.0101 127.23 91.1001 127.23V127.24Z" fill={colorTxt}/>
                    <path d="M124.1 71.9C124.1 72.93 123.89 73.89 123.49 74.78C123.11 75.67 122.58 76.46 121.9 77.14C121.22 77.79 120.42 78.32 119.49 78.72C118.59 79.1 117.62 79.29 116.59 79.29C115.56 79.29 114.58 79.1 113.65 78.72C112.75 78.31 111.96 77.79 111.28 77.14C110.63 76.46 110.1 75.68 109.69 74.78C109.31 73.89 109.12 72.92 109.12 71.9C109.12 70.88 109.31 69.95 109.69 69.05C110.1 68.13 110.63 67.34 111.28 66.69C111.96 66.01 112.75 65.48 113.65 65.11C114.58 64.7 115.55 64.5 116.59 64.5C117.63 64.5 118.59 64.7 119.49 65.11C120.42 65.49 121.22 66.02 121.9 66.69C122.58 67.34 123.11 68.13 123.49 69.05C123.9 69.94 124.1 70.89 124.1 71.9ZM122.18 127.24H110.95V83.72H122.18V127.24Z" fill={colorTxt}/>
                    <path d="M141.86 127.24H130.71V99.97C130.71 99.97 130.71 94.3 135.99 89.05C141.27 83.8 150.31 83.8 150.31 83.8H160.15V94.85H150.31C149.14 94.85 148.04 95.07 147 95.5C145.97 95.93 145.07 96.53 144.31 97.29C143.55 98.05 142.95 98.94 142.51 99.97C142.07 101 141.86 102.1 141.86 103.26V127.23V127.24Z" fill={colorTxt}/>
                    <path d="M178.49 71.9C178.49 72.93 178.29 73.89 177.88 74.78C177.5 75.67 176.97 76.46 176.29 77.14C175.61 77.79 174.81 78.32 173.88 78.72C172.98 79.1 172.02 79.29 170.98 79.29C169.94 79.29 168.97 79.1 168.04 78.72C167.14 78.31 166.35 77.79 165.67 77.14C165.02 76.46 164.49 75.68 164.08 74.78C163.7 73.89 163.51 72.92 163.51 71.9C163.51 70.88 163.7 69.95 164.08 69.05C164.49 68.13 165.02 67.34 165.67 66.69C166.35 66.01 167.14 65.48 168.04 65.11C168.97 64.7 169.95 64.5 170.98 64.5C172.01 64.5 172.98 64.7 173.88 65.11C174.81 65.49 175.61 66.02 176.29 66.69C176.97 67.34 177.5 68.13 177.88 69.05C178.29 69.94 178.49 70.89 178.49 71.9ZM176.57 127.24H165.34V83.72H176.57V127.24Z" fill={colorTxt}/>
                    <path d="M209.12 127.24H183.64V116.19H209.12C209.88 116.19 210.54 115.92 211.08 115.38C211.63 114.84 211.9 114.19 211.9 113.43C211.9 112.67 211.63 112.13 211.08 111.89C210.54 111.65 209.88 111.52 209.12 111.52H197.52C195.59 111.52 193.78 111.15 192.09 110.42C190.4 109.69 188.93 108.7 187.68 107.45C186.43 106.18 185.43 104.7 184.7 103.02C183.99 101.34 183.64 99.5401 183.64 97.6201C183.64 95.7001 183.99 93.9001 184.7 92.2201C185.44 90.5401 186.43 89.0801 187.68 87.8301C188.93 86.5801 190.4 85.6101 192.09 84.9101C193.78 84.1801 195.59 83.8101 197.52 83.8101H220.1V94.8601H197.52C196.76 94.8601 196.1 95.1301 195.56 95.6701C195.02 96.2101 194.74 96.8601 194.74 97.6201C194.74 98.3801 195.01 99.0801 195.56 99.6501C196.1 100.19 196.76 100.46 197.52 100.46H209.12C211.03 100.46 212.82 100.78 214.51 101.43C216.2 102.05 217.67 102.93 218.92 104.07C220.17 105.21 221.17 106.58 221.9 108.17C222.63 109.77 223 111.52 223 113.41C223 115.3 222.63 117.14 221.9 118.81C221.16 120.46 220.17 121.93 218.92 123.2C217.67 124.45 216.2 125.44 214.51 126.17C212.82 126.87 211.03 127.23 209.12 127.23V127.24Z" fill={colorTxt}/>
                    <path d="M33 101C37.1421 101 40.5 97.6421 40.5 93.5C40.5 89.3579 37.1421 86 33 86C28.8579 86 25.5 89.3579 25.5 93.5C25.5 97.6421 28.8579 101 33 101Z" fill={colorTxt}/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_458_5928">
                    <rect width="223" height="128.5" fill="white"/>
                </clipPath>
                <clipPath id="clip1_458_5928">
                    <rect width="111.81" height="75.82" fill="white" transform="translate(2.27002)"/>
                </clipPath>
                <clipPath id="clip2_458_5928">
                    <rect width="222.99" height="64.01" fill="white" transform="translate(0.00976562 64.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
};

LogoOsiris.propTypes = {
    colorTxt: PropTypes.string,
    colorVibe: PropTypes.string,
}

LogoOsiris.defaultProps = {
    colorTxt: "#101c3d",
    colorVibe: "#1c3e97",
};

export default LogoOsiris;