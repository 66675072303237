import * as React from "react";


function DivLanguage({ children, language,  ...other }) {

    return (

        <div style={{visibility: language !== document.documentElement.lang ? 'hidden' : 'visible',
            height: language !== document.documentElement.lang ? '0px': '',
            width: language !== document.documentElement.lang ? '0px' : ''}}
             {...other}>

            {language === document.documentElement.lang && (
                < >
                    {children}
                </>
            )}
        </div>
    );
}

export default DivLanguage;