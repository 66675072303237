import React from 'react';
import DivLanguage from "../../../components/DivLanguage";
import CardWithBorder from "../../../components/card/CardWithBorder";
import BtnDocumentDownload from "../../../components/ButtonDocumentDownload";


const HowOsirisDoItCenter = () => {

    return (
        <section className="how-do-it-center-section wave-section-top">

            <div className="snd-bg wave-section">
                <div className="center">
                    <DivLanguage language="fr" className="wrapper-demi">
                        <div className="title-n-text">
                            <h2>
                                Comment est construite la plateforme OSIRIS ?
                            </h2>
                            <p className="p20-medium">
                                Osiris est composé de plusieurs modules
                                pour répondre aux enjeux
                                de transformation et d’analyse des
                                données d’assurance.
                            </p>
                        </div>
                        <CardWithBorder title="La préparation des données"
                                        icon="fa-solid fa-list-check">
                            <div>
                                <p className="p18-regular">
                                    Algorithmes supervisés de transformation
                                    et de reconnaissance des données de
                                    gestion qui sont déposées dans l’outil
                                    (csv, txt, xls, pdf, access, …)
                                </p>
                            </div>

                        </CardWithBorder>
                    </DivLanguage>
                    <DivLanguage language="en" className="wrapper-demi">
                        <div className="title-n-text">
                            <h2>
                                How is the OSIRIS platform built?
                            </h2>
                            <p className="p20-medium">
                                Osiris is composed of several modules
                                to address the challenges
                                of transformation and analysis of
                                insurance data.
                            </p>
                        </div>
                        <CardWithBorder title="Data Preparation"
                                        icon="fa-solid fa-list-check">
                            <div>
                                <p className="p18-regular">
                                    Supervised algorithms for transformation
                                    and recognition of management data
                                    that are uploaded into the tool
                                    (csv, txt, xls, pdf, access, …)
                                </p>
                            </div>
                        </CardWithBorder>
                    </DivLanguage>


                    <DivLanguage language="fr" className="wrapper-demi">
                        <CardWithBorder title="Le suivi des données"
                                        icon="fa-solid fa-chart-line">
                            <div>
                                <p className="p18-regular" lang="fr">
                                    Les données sont organisées et classées
                                    pour faciliter la recherche dans le datalake.
                                    La collaboration est simplifiée avec des données accesibles
                                    pour tous les utilisateurs (suivant leurs droits).
                                </p>
                            </div>
                        </CardWithBorder>
                        <CardWithBorder title="Le workflow des traitements"
                                        icon="fa-solid fa-magic">
                            <div>
                                <p className="p18-regular">
                                    Le workflow d'Osiris permet d'organiser les tâches simples ou complexes
                                    sous forme de successions d'étapes. Chaque workflow validé est ensuite
                                    exécuté automatiquement. Un système de notifications est disponible pour
                                    alerter l'utilisateur quand un traitement se termine.
                                </p>
                            </div>

                        </CardWithBorder>
                    </DivLanguage>
                    <DivLanguage language="en" className="wrapper-demi">
                        <CardWithBorder title="Data Monitoring"
                                        icon="fa-solid fa-chart-line">
                            <div>
                                <p className="p18-regular" lang="fr">
                                    Data is organized and classified
                                    to facilitate searching in the datalake.
                                    Collaboration is simplified with data accessible
                                    to all users (according to their rights).
                                </p>
                            </div>
                        </CardWithBorder>
                        <CardWithBorder title="Processing Workflow"
                                        icon="fa-solid fa-magic">
                            <div>
                                <p className="p18-regular">
                                    The Osiris workflow allows organizing simple or complex tasks
                                    as a series of steps. Each validated workflow is then
                                    executed automatically. A notification system is available to
                                    alert the user when a process is completed.
                                </p>
                            </div>
                        </CardWithBorder>
                    </DivLanguage>


                    <DivLanguage language="fr" className="wrapper-demi">
                        <CardWithBorder title="Les rapports"
                                        icon="fa-regular fa-file-lines">
                            <div>
                                <p className="p18-regular">
                                    Osiris met à disposition une
                                    libraire d’algorithmes, de templates
                                    et de modèles permettant d’analyser
                                    et d’exploiter les données (compte
                                    de résultat, contrôles, screening
                                    embargo, suivi de risque, …)
                                </p>
                            </div>
                        </CardWithBorder>
                        <CardWithBorder title="Le claim module"
                                        icon="fa-solid fa-ambulance">
                            <div>
                                <p className="p18-regular">
                                    Le module de gestion des sinistres permet
                                    à nos clients Assureurs de suivre l’évolution
                                    de leurs sinistres. Il détecte automatiquement
                                    les nouveaux sinistres et les variations par
                                    rapport à des sinistres déjà enregistrés.
                                </p>
                            </div>

                        </CardWithBorder>
                    </DivLanguage>

                    <DivLanguage language="en" className="wrapper-demi">
                        <CardWithBorder title="Reports"
                                        icon="fa-regular fa-file-lines">
                            <div>
                                <p className="p18-regular">
                                    Osiris provides a library of algorithms, templates,
                                    and models to analyze and leverage data (income statement,
                                    controls, embargo screening, risk monitoring, …)
                                </p>
                            </div>
                        </CardWithBorder>
                        <CardWithBorder title="Claim Module"
                                        icon="fa-solid fa-ambulance">
                            <div>
                                <p className="p18-regular">
                                    The claims management module allows
                                    our insurer clients to track the evolution
                                    of their claims. It automatically detects
                                    new claims and variations compared
                                    to claims already recorded.
                                </p>
                            </div>
                        </CardWithBorder>
                    </DivLanguage>

                    <BtnDocumentDownload documentPath={"/APIs/apiguide-pdf.pdf"}/>


                </div>

            </div>

        </section>
    )
};

export default HowOsirisDoItCenter;