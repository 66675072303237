import BtnClickCenter from "./BtnClickCenter";
import React, {useState} from "react";
import DivLanguage from "./DivLanguage";
import Input from "./forms/Input";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "./Button";
import CardWithBorder from "./card/CardWithBorder";
import './../style/forms.scss';


export const DocumentTypes = {
    SAASMANGEMENT: "/SaaS/saas-management-pdf.pdf",
    APIGUIDE: "/APIs/apiguide-pdf.pdf",
    HELPGUIDE: "/Features/help-pdf.pdf",
    HOWTOUSEAPI: "/HowTo/use-OsirisR-pdf.pdf",
    HOWTOCONFIGPACK: "/HowTo/configureAPackage-pdf.pdf",
    HOWTOCONFIGTASK: "/HowTo/configureATask-pdf.pdf",
    HOWTOUSEOSIRISR: "/HowTo/use-OsirisR-pdf.pdf",
    HOWTOUSELIVETEST: "/HowTo/use-livetesting-pdf.pdf"
};

const BtnDocumentDownload = ({
                                 buttonLabel = "default",
                                 documentPath = DocumentTypes.SAASMANGEMENT,
                                 buttonClass = "txt-color-dark"
                             }) => {
    const [showContactForm, setShowContactForm] = useState(false);

    const toggleForm = () => {
        setShowContactForm(!showContactForm);
    };
    const getDocumentPathLanguage = () => {
        return document.documentElement.lang + documentPath;
    }
    const KEYSITE = process.env.REACT_APP_CAPTCHA_KEY;
    const [formData, setFormData] = useState(
        {
            email: ''
        });
    const recaptchaRef = React.createRef();
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const getButtonLabel = () => {
        if (buttonLabel !== "default") {
            return buttonLabel;
        } else {
            return document.documentElement.lang === "en" ? "More details" : "Plus de détails";
        }
    }

    const [captchaState, setCaptchaState] = useState(
        {
            expired: false,
            recaptchaLoaded: false
        });

    const handleChangeCaptcha = value => {

        if (value === null) {
            setCaptchaState((prevData) => ({
                expired: true,
                recaptchaLoaded: prevData.recaptchaLoaded
            }));
        } else {
            setCaptchaState(value);
        }

    };

    const asyncScriptOnLoad = () => {
        setCaptchaState((prevData) => ({
            expired: prevData.expired,
            recaptchaLoaded: true
        }));
        console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
    };


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleClose = (e) => {
        setShowContactForm(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (captchaState.expired) {
            return;
        }
        const captchaToken = await recaptchaRef.current.executeAsync();

        if (!captchaToken) {
            console.error('Vérification CAPTCHA non complétée');
            return;
        }
        try {
            const formDataWithToken = {
                "contactData": formData,
                "token": captchaToken
            }
            console.log("call document API")
            const res = await callDocumentAPI(formDataWithToken);
            console.log("call document API terminé")
            setFormData({
                email: ''
            });
            buildSuccessMessage();
            setSubmitted(true)
            console.log(submitted)
        } catch (error) {
            buildErrorMessage();
        }
    };
    const buildSuccessMessage = () => {
        setSuccessMessage(document.documentElement.lang === "en" ? "Message was sent, check your mail box!" :
            "Le message a été envoyé, vérifiez votre boîte mail!");
        console.log(successMessage);

    }
    const buildErrorMessage = () => {
        setSuccessMessage(document.documentElement.lang === "en" ? "An error occurred. Please try again." :
            "Une erreur s'est produite. Veuillez réessayer.");

    }

    function getServerAddress() {
        return process.env.REACT_APP_API_ENDPOINT;
    }

    const callDocumentAPI = async (data) => {

        const apiEndpoint = getServerAddress() + "api/v1/documents/requestDocument";
        console.log(apiEndpoint);

        data.keyPath = getDocumentPathLanguage();

        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        console.log("Appel API endpoint terminé")
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'envoi des données');
        }
        console.log("Appel API endpoint terminé sans erreur");
        const responseData = await response.json();
        console.log("Appel API endpont terminé " + responseData);
    };
    return (
        <section>
            <DivLanguage language="fr">
                <BtnClickCenter value={getButtonLabel()}
                                onclick={toggleForm}
                                className={buttonClass}/>
                {showContactForm &&
                    <div className="dialog">
                        <div className="dialog-content">
                            <CardWithBorder title="Obtenez votre lien de téléchargement"
                                            className="dark-card-with-border"
                                            icon="fa fa-paper-plane"
                                            showButtonClose={true} handleClose={handleClose}>
                                <form onSubmit={handleSubmit} >


                                    <Input label="Mail"
                                           name="email"
                                           type="email"
                                           value={formData.email}
                                           onChange={handleChange}
                                           required={true}/>


                                    {/* Intégration du composant reCAPTCHA */}
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={KEYSITE}
                                        size="invisible"
                                        onChange={handleChangeCaptcha}
                                        asyncScriptOnLoad={asyncScriptOnLoad}
                                    />

                                    {!submitted && <Button type="submit"
                                                           value="Télécharger"
                                                           disabled={!captchaState.recaptchaLoaded}
                                                           className="g-recaptcha"/>}
                                    {successMessage && <div className="success-message">{successMessage}</div>}
                                </form>
                            </CardWithBorder>
                        </div>
                    </div>
                }
            </DivLanguage>
            <DivLanguage language="en">
                <BtnClickCenter value={getButtonLabel()}
                                onclick={toggleForm}
                                className={buttonClass}/>
                {
                    showContactForm &&
                    <CardWithBorder title="Get your download link"
                                    className="dark-card-with-border center"
                                    icon="fa fa-paper-plane"
                                    showButtonClose={true} handleClose={handleClose}>


                        <form onSubmit={handleSubmit}>

                            <Input label="Mail"
                                   name="email"
                                   type="email"
                                   value={formData.email}
                                   onChange={handleChange}
                                   required={true}/>

                            {/* Intégration du composant reCAPTCHA */}
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={KEYSITE}
                                size="invisible"
                                onChange={handleChangeCaptcha}
                                asyncScriptOnLoad={asyncScriptOnLoad}
                            />

                            {!submitted && <Button type="submit"
                                                   value="Download"
                                                   disabled={!captchaState.recaptchaLoaded}
                                                   className="g-recaptcha"/>}
                            {successMessage && <div className="success-message">{successMessage}</div>}
                        </form>


                    </CardWithBorder>
                }
            </DivLanguage>

        </section>
    )
        ;
};
export default BtnDocumentDownload;