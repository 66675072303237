import React from 'react';
import VismeNewsLetterComponent from "../../../components/visme/NewsletterSubscribe";
import SectionContact from "../../../components/SectionContact";
import WhyUseOsirisTop from "./WhyUseOsirisTop";
import WhyUseOsirisCenter from "./WhyUseOsirisCenter";
import "./whyuseosiris.scss"


const WhyUseOsiris = () => {

    return (
        <>
            <WhyUseOsirisTop/>
            <WhyUseOsirisCenter/>
            <SectionContact className="white-wave"/>
        </>
)
};

export default WhyUseOsiris;