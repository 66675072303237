import React from 'react';
import "../../style/home.scss"
import SectionTop from "./SectionTop";
import HomeMiddle from "./HomeMiddle";
import SectionContact from "../../components/SectionContact";
import SectionWhyOsiris from "./SectionWhyOsiris";



const HomePage = (props) => {
    return (
        <>
            <SectionTop/>


            <HomeMiddle/>

            <SectionContact className="white-wave"/>

        </>
    )
};

export default HomePage;