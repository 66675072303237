import React, {useEffect} from 'react';
import DivLanguage from "../DivLanguage";

const VismeNewsLetterComponent = () => {
    useEffect(() => {
        // Créez une balise script et ajoutez-la au DOM
        const script = document.createElement('script');
        script.src = 'https://static-bundles.visme.co/forms/vismeforms-embed.js';
        script.async = true;
        console.log("Script visme ajouté aux sources")
        document.body.appendChild(script);

        // Nettoyage du script lorsque le composant est démonté
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section>
            <DivLanguage language={"en"}>
                <div className="visme_d" data-title="Newsletter-EN" data-url="31nr44j4-newsletter-en?fullPage=true"
                     data-domain="forms" data-full-page="true" data-min-height="100vh" data-form-id="100652"></div>
            </DivLanguage>
            <DivLanguage language={"fr"}>
                <div className="visme_d" data-title="Newsletter-FR" data-url="4d61y999-newsletter-fr?fullPage=true"
                     data-domain="forms" data-full-page="true" data-min-height="100vh" data-form-id="100884"></div>
            </DivLanguage>
        </section>


    )
        ;
};

export default VismeNewsLetterComponent;
