import React from 'react';
import Button from "./Button";

const BtnClickCenter = ({value="Contacter nos experts", className, onclick=""}) => {
    return (
        <div className="btn-center">
            <Button value={value}
                    onClick={onclick}
                    className={className + " big-button"}/>
        </div>
    )
};


export default BtnClickCenter;