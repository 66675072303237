import React from 'react';


const CardBigIcon = ({title="Titre", icon="fa-solid fa-bacterium",
                         children="Description un peu longue"}) => {
    return (
        <div className="card-big-icon">
            <div className="icon">
                <i className={icon}></i>
            </div>
            <h3>
                {title}
            </h3>
            <>
                {children}
            </>
        </div>
    )
};

export default CardBigIcon;