import React from 'react';
import LogoOsiris from "../../../components/logo/LogoOsiris";
import DivLanguage from "../../../components/DivLanguage";


const WhyUseOsirisTop = (props) => {
    return (
        <section className="osiris-top-section wave-section">
            <DivLanguage language="fr" className="center">
                <div className="title">
                    <div className="line">
                        <h1>
                            Les utilisations d'Osiris
                        </h1>
                        <div className="logo">
                            <LogoOsiris/>
                        </div>
                    </div>
                </div>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <div className="title">
                    <div className="line">
                        <h1>
                            Osiris usages
                        </h1>
                        <div className="logo">
                            <LogoOsiris/>
                        </div>
                    </div>
                </div>
            </DivLanguage>

        </section>
    )
};

export default WhyUseOsirisTop;