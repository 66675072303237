import React from 'react';

const CardLineChecked = ({text, isChecked}) => {
    if (isChecked)
        return (
            <div className="icon-n-check" style={{display:"flex", justifyContent: "flex-start", width:"auto"}} >
                <div >
                    <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="23.6842" height="23.6842" rx="11.8421" fill="#FF891C" fill-opacity="0.3"/>
                        <path d="M11.0522 11.0527L15.7891 15.7895L29.9996 1.57898" stroke="#FF891C" stroke-width="2.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <p className="p18-regular">
                    {text}
                </p>

            </div>
        )
    return (
        <div className="icon-n-check" style={{display:"flex", justifyContent: "flex-start", width:"auto"}}>
            <div>
                <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="23.6842" height="23.6842" rx="11.8421" fill="#FF891C" fill-opacity="0.3"/>
                    <path d="M11.0522 11.0527L15.7891 15.7895L29.9996 1.57898" stroke="#FF891C" stroke-opacity="0"
                          stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <p className="p18-regular">
                {text}
            </p>

        </div>
    )
};

export default CardLineChecked;