import {Link} from "react-router-dom";
import React from "react";


const LinkIcon=({to ="/", value="Label",
                className = "nav-link",
                iconClass="fa fa-rocket" })=>{
    return(
        <Link to={to}>

            <a className={className} role="button" >
                <i className={iconClass}></i>
                <label style={{marginLeft:'10px'}}>
                    {value}
                </label>
            </a>

        </Link>
    )
};

export default LinkIcon;