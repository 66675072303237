import React from 'react';
import "../../style/who-are-we.scss";
import DivLanguage from "../../components/DivLanguage";
import CardWithBorder from "../../components/card/CardWithBorder";

const SectionWawTeam = (props) => {
    return (
        <section className="waw-team wave-section">
            <div className="center">

                <DivLanguage language="fr" className="container">
                    <h2>
                        Notre équipe = Actuariat x Technologie
                    </h2>
                    <p className="p20-regular">
                        Notre équipe est composée de professionnels passionnés par
                        l'assurance. Ils aiment relever des défis autour d'enjeux liés à des problématiques
                        actuarielles et/ou technologiques.
                        Lors de nos missions de conseils, nous apportons notre expertise et notre savoir-faire
                        pour simplifier et harmoniser les processus actuariels et financiers.
                    </p>
                    <p className="p20-regular">
                        Nous sommes donc fiers de compter dans notre équipe des
                        personnes passionnées par les technologies de
                        la donnée, ou encore par la modélisation et des techniques de l'assurance.
                    </p>
                </DivLanguage>

                <DivLanguage language="fr" className="wrapper-demi">
                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-umbrella"
                                    title="Nos champs d'expertise">
                        <p className="p18-regular">
                            <strong>Comptabilité </strong>
                            Multi-norme de l’Assurance
                            (IFRS, Statutaire, Solvabilité 2),
                            comptabilité technique
                        </p>
                        <p className="p18-regular">
                            <strong>Modélisation </strong>
                            des actifs et des
                            passifs : formule standard,
                            projection de bilans, générateurs
                            de scénarios économiques
                        </p>
                        <p className="p18-regular">
                            <strong>Provisionnement, </strong>
                            tarification, gestion Actif-Passif,
                            processus ORSA
                        </p>
                    </CardWithBorder>

                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-microchip"
                                    title="Technologies">
                        <p className="p18-regular">
                            <strong>Cloud Computing : </strong>
                            AWS, OVH, Azure
                        </p>
                        <p className="p18-regular">
                            <strong>Data </strong>
                            engineering / <strong>data </strong>
                            science : R, Python, SQL, Scala
                        </p>
                        <p className="p18-regular">
                            <strong>Développement d'applications : </strong>
                            Java EE, Javascript, …
                        </p>
                    </CardWithBorder>
                </DivLanguage>
                <DivLanguage language="en" className="container">
                    <h2>
                        Our Team = Actuarial Science x Technology
                    </h2>
                    <p className="p20-regular">
                        Our team is made up of professionals passionate about insurance. They love taking on challenges
                        related to actuarial and/or technological issues. During our consulting missions, we bring our
                        expertise and know-how to simplify and harmonize actuarial and financial processes.
                    </p>
                    <p className="p20-regular">
                        We are therefore proud to have in our team people passionate about data technologies, as well as
                        modeling and insurance techniques.
                    </p>
                </DivLanguage>

                <DivLanguage language="en" className="wrapper-demi">
                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-umbrella"
                                    title="Our Areas of Expertise">
                        <p className="p18-regular">
                            <strong>Accounting </strong>
                            Multi-standard in Insurance
                            (IFRS, Statutory, Solvency II),
                            technical accounting
                        </p>
                        <p className="p18-regular">
                            <strong>Modeling </strong>
                            of assets and liabilities: standard formula,
                            balance sheet projection, economic scenario generators
                        </p>
                        <p className="p18-regular">
                            <strong>Provisioning, </strong>
                            pricing, Asset-Liability Management,
                            ORSA processes
                        </p>
                    </CardWithBorder>

                    <CardWithBorder className="blue-card-with-border"
                                    icon="fa-solid fa-microchip"
                                    title="Technologies">
                        <p className="p18-regular">
                            <strong>Cloud Computing: </strong>
                            AWS, OVH, Azure
                        </p>
                        <p className="p18-regular">
                            <strong>Data </strong>
                            engineering / <strong>data </strong>
                            science: R, Python, SQL, Scala
                        </p>
                        <p className="p18-regular">
                            <strong>Application Development: </strong>
                            Java EE, Javascript, …
                        </p>
                    </CardWithBorder>
                </DivLanguage>


            </div>


        </section>
    )
};

export default SectionWawTeam;