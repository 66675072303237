import React from 'react';
import LogoOsiris from "../../../components/logo/LogoOsiris";
import DivLanguage from "../../../components/DivLanguage";



const FeaturesTop = ({topTitleFR="Les fonctionnalités d'Osiris", topTitleEN = "Osiris features"}) => {
    return (
        <section className="top-section wave-section">
            <DivLanguage language="fr" className="center">
                <div className="title">
                    <div className="line">
                        <h1>
                            {topTitleFR}
                        </h1>
                        <div className="logo">
                            <LogoOsiris/>
                        </div>
                    </div>
                </div>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <div className="title">
                    <div className="line">
                        <h1>
                            {topTitleEN}
                        </h1>
                        <div className="logo">
                            <LogoOsiris/>
                        </div>
                    </div>
                </div>
            </DivLanguage>

        </section>
    )
};

export default FeaturesTop;