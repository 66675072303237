import React, {useState} from 'react';
import Input from "./Input";
import Button from "../Button";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = ({subscribeDocument=false, documentKeyPath = ""}) => {

    const KEYSITE = process.env.REACT_APP_CAPTCHA_KEY;
    const [formData, setFormData] = useState(
        {
        firstname: '',
        lastname: '',
        email: '',
        message: ''
    });
    const recaptchaRef = React.createRef();
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);


    const [captchaState, setCaptchaState] = useState(
        {
        expired: false,
        recaptchaLoaded: false
    });

    const handleChangeCaptcha = value => {
        console.log("onChange prop - Captcha value:", value);

        if (value === null) {
            setCaptchaState((prevData)=> ({
                expired: true,
                recaptchaLoaded: prevData.recaptchaLoaded
            }));
        }else{
            setCaptchaState(value);
        }

    };

    const asyncScriptOnLoad = () => {
        setCaptchaState((prevData)=> ({
            expired: prevData.expired,
            recaptchaLoaded: true
        }));
        console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (captchaState.expired){
            return;
        }
        const captchaToken = await recaptchaRef.current.executeAsync();
        console.log(captchaToken)
        if (!captchaToken) {
            console.error('Vérification CAPTCHA non complétée');
            return;
        }
        try {
            const formDataWithToken={
                "contactData": formData,
                "token": captchaToken
            }
            // Envoyer formDataWithToken à votre serveur
            // sendData(formDataWithToken);
            if (subscribeDocument){
                console.log("Request document")
                await callDocumentAPI(formDataWithToken);
            }else{
                await callContactAPI(formDataWithToken);
            }

            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                message: ''
            });
            setSuccessMessage('Votre message a été envoyé avec succès !');
            setSubmitted(true)
        } catch (error) {
            setSuccessMessage('Une erreur s\'est produite. Veuillez réessayer.');
        }
    };
    function getServerAddress(){
        return process.env.REACT_APP_API_ENDPOINT;
    }
    const callContactAPI = async (data) => {

        const apiEndpoint = getServerAddress()+"api/v1/contact";
        console.log(apiEndpoint)
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'envoi des données');
        }

        const responseData = await response.json();
        console.log('Succès:', responseData);
    };

    const callDocumentAPI = async (data) => {

        const apiEndpoint = getServerAddress()+"api/v1/documents/requestDocument";
        console.log(apiEndpoint)

        data.keyPath = documentKeyPath;

        const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'envoi des données');
        }

        const responseData = await response.json();
        console.log('Succès:', responseData);
    };


    return (

        <form onSubmit={handleSubmit}>


            {!subscribeDocument && <Input label="Prénom"
                   name="firstname"
                   type="text"
                   value={formData.firstname}
                   onChange={handleChange}
                   required={true}/>}

            {!subscribeDocument && <Input label="Nom"
                   name="lastname"
                   type="text"
                   value={formData.lastname}
                   onChange={handleChange}
                   required={true}/>}

            <Input label="Mail"
                   name="email"
                   type="email"
                   value={formData.email}
                   onChange={handleChange}
                   required={true}/>


            {!subscribeDocument &&<div className="formInput">
                <label>Message</label>
                <textarea name="message"
                          value={formData.message}
                          onChange={handleChange}/>
            </div>}

            {/* Intégration du composant reCAPTCHA */}
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={KEYSITE}
                size="invisible"
                onChange={handleChangeCaptcha}
                asyncScriptOnLoad={asyncScriptOnLoad}
            />

            {!subscribeDocument && !submitted && <Button type="submit"
                    value="Envoyer"
                    disabled={!captchaState.recaptchaLoaded}
                    className="g-recaptcha" />}
            {subscribeDocument && !submitted && <Button type="submit"
                                                         value="Télécharger"
                                                         disabled={!captchaState.recaptchaLoaded}
                                                         className="g-recaptcha" />}
            {successMessage && <div className="success-message">{successMessage}</div>}
        </form>


    )
};

export default ContactForm;