import React from 'react';
import "../../style/components/card-howto-guide.scss"
import BtnDocumentDownload, {DocumentTypes} from "../ButtonDocumentDownload";

const CardHowToGuide = ({cardTitle="Card title", cardSubTitle="Sous titre", image,
                            buttonLabel = "default", documentPath = DocumentTypes.SAASMANGEMENT,
                            children}) => {

    return (
        <div className="howto-card">
            <div className="howto-guide">
                <div className="top">
                    <img src={image} alt="PDG"/>
                    <h3>{cardTitle}</h3>
                    <h4 className="span-orange">{cardSubTitle}</h4>
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
            <div className="bottom">
                <BtnDocumentDownload documentPath={documentPath} buttonLabel={buttonLabel}/>
            </div>
        </div>
    )
};

export default CardHowToGuide;