import React from 'react';

const LogoAlphawave = ({color}) => {
    return (
        <svg width="176" height="35" viewBox="0 0 176 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.408 8.09998C11.724 8.09998 12.942 8.35198 14.062 8.85598C15.21 9.33198 16.204 10.004 17.044 10.872C17.912 11.712 18.584 12.706 19.06 13.854C19.564 14.974 19.816 16.192 19.816 17.508V26.958H10.408C9.12001 26.958 7.90201 26.72 6.75401 26.244C5.60601 25.74 4.59801 25.054 3.73001 24.186C2.86201 23.318 2.17601 22.31 1.67201 21.162C1.19601 20.014 0.958008 18.796 0.958008 17.508C0.958008 16.192 1.19601 14.974 1.67201 13.854C2.17601 12.706 2.86201 11.712 3.73001 10.872C4.59801 10.004 5.60601 9.33198 6.75401 8.85598C7.90201 8.35198 9.12001 8.09998 10.408 8.09998ZM16.078 23.22V17.508C16.078 16.724 15.924 15.996 15.616 15.324C15.336 14.624 14.93 14.022 14.398 13.518C13.894 12.986 13.292 12.58 12.592 12.3C11.92 11.992 11.192 11.838 10.408 11.838C9.62401 11.838 8.88201 11.992 8.18201 12.3C7.48201 12.58 6.86601 12.986 6.33401 13.518C5.83001 14.022 5.42401 14.624 5.11601 15.324C4.83601 15.996 4.69601 16.724 4.69601 17.508C4.69601 18.292 4.83601 19.034 5.11601 19.734C5.42401 20.434 5.83001 21.05 6.33401 21.582C6.86601 22.086 7.48201 22.492 8.18201 22.8C8.88201 23.08 9.62401 23.22 10.408 23.22H16.078Z" fill={color}/>
            <path d="M21.7746 0.539978H25.5126V26.958H21.7746V0.539978Z" fill={color}/>
            <path d="M37.1906 8.18398C38.5066 8.18398 39.7246 8.43598 40.8446 8.93998C41.9926 9.41598 43.0006 10.088 43.8686 10.956C44.7366 11.796 45.4086 12.79 45.8846 13.938C46.3886 15.058 46.6406 16.276 46.6406 17.592C46.6406 18.88 46.3886 20.098 45.8846 21.246C45.4086 22.394 44.7366 23.402 43.8686 24.27C43.0006 25.138 41.9926 25.824 40.8446 26.328C39.7246 26.804 38.5066 27.042 37.1906 27.042H31.5206V34.602H27.7826V17.592C27.7826 16.276 28.0206 15.058 28.4966 13.938C29.0006 12.79 29.6726 11.796 30.5126 10.956C31.3806 10.088 32.3886 9.41598 33.5366 8.93998C34.6846 8.43598 35.9026 8.18398 37.1906 8.18398ZM37.1906 23.304C37.9746 23.304 38.7166 23.164 39.4166 22.884C40.1166 22.576 40.7186 22.17 41.2226 21.666C41.7546 21.134 42.1606 20.518 42.4406 19.818C42.7486 19.118 42.9026 18.376 42.9026 17.592C42.9026 16.808 42.7486 16.08 42.4406 15.408C42.1606 14.708 41.7546 14.106 41.2226 13.602C40.7186 13.07 40.1166 12.664 39.4166 12.384C38.7166 12.076 37.9746 11.922 37.1906 11.922C36.4066 11.922 35.6646 12.076 34.9646 12.384C34.2926 12.664 33.6906 13.07 33.1586 13.602C32.6546 14.106 32.2486 14.708 31.9406 15.408C31.6606 16.08 31.5206 16.808 31.5206 17.592V23.304H37.1906Z" fill={color}/>
            <path d="M58.1282 8.09998C59.4442 8.09998 60.6622 8.35198 61.7822 8.85598C62.9302 9.33198 63.9382 10.004 64.8062 10.872C65.6742 11.712 66.3462 12.706 66.8222 13.854C67.3262 14.974 67.5782 16.192 67.5782 17.508V26.958H63.8402V17.508C63.8402 16.724 63.6862 15.996 63.3782 15.324C63.0982 14.624 62.6922 14.022 62.1602 13.518C61.6562 12.986 61.0542 12.58 60.3542 12.3C59.6542 11.992 58.9122 11.838 58.1282 11.838C57.3442 11.838 56.6022 11.992 55.9022 12.3C55.2302 12.58 54.6282 12.986 54.0962 13.518C53.5922 14.022 53.1862 14.624 52.8782 15.324C52.5982 15.996 52.4582 16.724 52.4582 17.508V26.958H48.7202V0.539978H52.4582V10.032C53.2422 9.41598 54.1102 8.93998 55.0622 8.60398C56.0422 8.26798 57.0642 8.09998 58.1282 8.09998Z" fill={color}/>
            <path d="M78.0608 8.09998C79.3768 8.09998 80.5948 8.35198 81.7148 8.85598C82.8628 9.33198 83.8568 10.004 84.6968 10.872C85.5648 11.712 86.2368 12.706 86.7128 13.854C87.2168 14.974 87.4688 16.192 87.4688 17.508V26.958H78.0608C76.7728 26.958 75.5548 26.72 74.4068 26.244C73.2588 25.74 72.2508 25.054 71.3828 24.186C70.5148 23.318 69.8288 22.31 69.3248 21.162C68.8488 20.014 68.6108 18.796 68.6108 17.508C68.6108 16.192 68.8488 14.974 69.3248 13.854C69.8288 12.706 70.5148 11.712 71.3828 10.872C72.2508 10.004 73.2588 9.33198 74.4068 8.85598C75.5548 8.35198 76.7728 8.09998 78.0608 8.09998ZM83.7308 23.22V17.508C83.7308 16.724 83.5768 15.996 83.2688 15.324C82.9888 14.624 82.5828 14.022 82.0508 13.518C81.5468 12.986 80.9448 12.58 80.2448 12.3C79.5728 11.992 78.8448 11.838 78.0608 11.838C77.2768 11.838 76.5348 11.992 75.8348 12.3C75.1348 12.58 74.5188 12.986 73.9868 13.518C73.4828 14.022 73.0768 14.624 72.7688 15.324C72.4888 15.996 72.3488 16.724 72.3488 17.508C72.3488 18.292 72.4888 19.034 72.7688 19.734C73.0768 20.434 73.4828 21.05 73.9868 21.582C74.5188 22.086 75.1348 22.492 75.8348 22.8C76.5348 23.08 77.2768 23.22 78.0608 23.22H83.7308Z" fill={color}/>
            <path d="M122.565 8.14198V17.55C122.565 18.838 122.313 20.056 121.809 21.204C121.333 22.352 120.661 23.36 119.793 24.228C118.925 25.096 117.917 25.782 116.769 26.286C115.649 26.762 114.431 27 113.115 27C111.575 27 110.147 26.664 108.831 25.992C107.515 25.292 106.423 24.354 105.555 23.178C104.687 24.354 103.595 25.292 102.279 25.992C100.991 26.664 99.5634 27 97.9954 27C96.7074 27 95.4894 26.762 94.3414 26.286C93.1934 25.782 92.1854 25.096 91.3174 24.228C90.4494 23.36 89.7634 22.352 89.2594 21.204C88.7834 20.056 88.5454 18.838 88.5454 17.55V8.14198H92.3254V17.55C92.3254 18.334 92.4654 19.076 92.7454 19.776C93.0534 20.476 93.4594 21.092 93.9634 21.624C94.4954 22.128 95.0974 22.534 95.7694 22.842C96.4694 23.122 97.2114 23.262 97.9954 23.262C98.7794 23.262 99.5214 23.122 100.221 22.842C100.921 22.534 101.523 22.128 102.027 21.624C102.559 21.092 102.965 20.476 103.245 19.776C103.553 19.076 103.707 18.334 103.707 17.55V8.14198H107.445V17.55C107.445 18.334 107.585 19.076 107.865 19.776C108.173 20.476 108.579 21.092 109.083 21.624C109.615 22.128 110.217 22.534 110.889 22.842C111.589 23.122 112.331 23.262 113.115 23.262C113.899 23.262 114.641 23.122 115.341 22.842C116.041 22.534 116.643 22.128 117.147 21.624C117.679 21.092 118.085 20.476 118.365 19.776C118.673 19.076 118.827 18.334 118.827 17.55V8.14198H122.565Z" fill={color}/>
            <path d="M133.309 8.09998C134.625 8.09998 135.843 8.35198 136.963 8.85598C138.111 9.33198 139.105 10.004 139.945 10.872C140.813 11.712 141.485 12.706 141.961 13.854C142.465 14.974 142.717 16.192 142.717 17.508V26.958H133.309C132.021 26.958 130.803 26.72 129.655 26.244C128.507 25.74 127.499 25.054 126.631 24.186C125.763 23.318 125.077 22.31 124.573 21.162C124.097 20.014 123.859 18.796 123.859 17.508C123.859 16.192 124.097 14.974 124.573 13.854C125.077 12.706 125.763 11.712 126.631 10.872C127.499 10.004 128.507 9.33198 129.655 8.85598C130.803 8.35198 132.021 8.09998 133.309 8.09998ZM138.979 23.22V17.508C138.979 16.724 138.825 15.996 138.517 15.324C138.237 14.624 137.831 14.022 137.299 13.518C136.795 12.986 136.193 12.58 135.493 12.3C134.821 11.992 134.093 11.838 133.309 11.838C132.525 11.838 131.783 11.992 131.083 12.3C130.383 12.58 129.767 12.986 129.235 13.518C128.731 14.022 128.325 14.624 128.017 15.324C127.737 15.996 127.597 16.724 127.597 17.508C127.597 18.292 127.737 19.034 128.017 19.734C128.325 20.434 128.731 21.05 129.235 21.582C129.767 22.086 130.383 22.492 131.083 22.8C131.783 23.08 132.525 23.22 133.309 23.22H138.979Z" fill={color}/>
            <path d="M160.971 8.01598L151.143 26.916L141.357 8.01598H145.557C145.949 8.77198 146.397 9.63998 146.901 10.62C147.405 11.572 147.909 12.552 148.413 13.56C148.917 14.54 149.407 15.492 149.883 16.416C150.387 17.34 150.807 18.138 151.143 18.81C151.563 17.998 152.039 17.074 152.571 16.038C153.131 14.974 153.677 13.924 154.209 12.888C154.741 11.852 155.231 10.9 155.679 10.032C156.127 9.13598 156.477 8.46398 156.729 8.01598H160.971Z" fill={color}/>
            <path d="M174.373 20.322L177.019 22.968L175.717 24.27C174.821 25.166 173.799 25.852 172.651 26.328C171.503 26.804 170.299 27.042 169.039 27.042C167.779 27.042 166.575 26.804 165.427 26.328C164.279 25.852 163.257 25.166 162.361 24.27C161.437 23.346 160.751 22.31 160.303 21.162C159.855 19.986 159.631 18.796 159.631 17.592C159.631 16.388 159.855 15.212 160.303 14.064C160.751 12.916 161.437 11.88 162.361 10.956C163.285 10.032 164.321 9.34598 165.469 8.89798C166.645 8.42198 167.835 8.18398 169.039 8.18398C170.243 8.18398 171.419 8.42198 172.567 8.89798C173.743 9.34598 174.793 10.032 175.717 10.956L177.019 12.258L166.561 22.716C167.625 23.248 168.745 23.43 169.921 23.262C171.125 23.066 172.175 22.52 173.071 21.624L174.373 20.322ZM165.007 13.56C163.887 14.68 163.327 16.024 163.327 17.592C163.327 18.46 163.523 19.286 163.915 20.07C164.251 19.734 164.755 19.23 165.427 18.558C166.127 17.858 166.855 17.13 167.611 16.374C168.395 15.59 169.137 14.848 169.837 14.148C170.565 13.448 171.125 12.902 171.517 12.51C170.453 11.978 169.319 11.796 168.115 11.964C166.939 12.132 165.903 12.664 165.007 13.56Z" fill={color}/>
        </svg>
    )
};

// LogoAlphawave.propTypes = {
//     color: PropTypes.string,
// }
//
// LogoAlphawave.defaultProps = {
//     color: "#101c3d",
// };

export default LogoAlphawave;