import React from 'react';
import VismeNewsLetterComponent from "../../../components/visme/NewsletterSubscribe";
import SectionContact from "../../../components/SectionContact";
import FeaturesTop from "./FeaturesTop";
import WhatIsOsirisCenter from "./WhatIsOsirisCenter";
import "./whatdoesosiris.scss";
import WhatDoesOsirisCenter from "./WhatDoesOsirisCenter";

const WhatDoesOsiris = () => {

    return (
        <>
            <FeaturesTop topTitleEN={"What does Osiris?"} topTitleFR={"Que fait Osiris?"}/>
            <WhatDoesOsirisCenter/>
            <SectionContact className="white-wave"/>
        </>
)
};

export default WhatDoesOsiris;