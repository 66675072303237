import React from 'react';
import "../style/legal-informations.scss";
import DivLanguage from "../components/DivLanguage";

const LegalInformations = (props) => {
    return (
        <>
            <section className="legal-informations-top-section wave-section">
                <DivLanguage language="fr" className="center">
                    <div className="title">
                        <h1>Mentions légales</h1>
                        <p className="p20-light">
                            Conformément aux dispositions des articles
                            6-III et 19 de la Loi n° 2004-575 du 21
                            juin 2004 pour la Confiance dans l'économie
                            numérique, dite L.C.E.N., nous portons à
                            la connaissance des utilisateurs et
                            visiteurs du site : www.alphawave.fr
                            les informations suivantes :
                        </p>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <div className="title">
                        <h1>Legal Notice</h1>
                        <p className="p20-light">
                            In accordance with the provisions of articles
                            6-III and 19 of Law No. 2004-575 of June 21, 2004
                            for Confidence in the Digital Economy, known as L.C.E.N.,
                            we inform the users and visitors of the site: www.alphawave.fr
                            of the following information:
                        </p>
                    </div>
                </DivLanguage>

            </section>

            <section className="legal-informations-sec2 bg-white">
                <DivLanguage language="fr" className="center">
                    <div className="title">
                        <h2>
                            Éditeur
                        </h2>
                        <p className="p18-medium">
                            Le site <span className="span-blue"> www.alphawave.fr </span>
                            est la propriété exclusive de<strong> SAS Alphawave, </strong>
                            qui l'édite.
                        </p>
                        <p className="p18-medium">
                            Alphawave
                            SAS au capital de 3000 €
                        </p>
                        <p className="p18-medium">
                            Tél : 0188327151
                        </p>
                        <p className="p18-medium">
                            8 Rue Blanche 75009 Paris
                        </p>
                        <p className="p18-medium">
                            Immatriculée au Registre du Commerce et des
                            Sociétés de PARIS sous le numéro 810777888
                        </p>
                        <p className="p18-medium">
                            Numéro TVA intracommunautaire :
                            FR 55 510777888
                        </p>
                        <p className="p18-medium">
                            Adresse de courrier électronique :
                            <span className="span-blue"> sebastien.potherat@alpahwave.fr</span>
                        </p>
                        <p className="p18-medium">
                            Directeur de la publication :
                            <strong> Sébastien Potherat</strong>
                        </p>
                        <p className="p18-medium">
                            Contactez le responsable de la publication :
                            <span className="span-blue"> sebastien.potherat@alpahwave.fr</span>
                        </p>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="center">
                    <div className="title">
                        <h2>
                            Publisher
                        </h2>
                        <p className="p18-medium">
                            The website <span className="span-blue"> www.alphawave.fr </span>
                            is the exclusive property of<strong> SAS Alphawave, </strong>
                            which publishes it.
                        </p>
                        <p className="p18-medium">
                            Alphawave
                            SAS with a capital of €3000
                        </p>
                        <p className="p18-medium">
                            Phone: 0188327151
                        </p>
                        <p className="p18-medium">
                            8 Rue Blanche 75009 Paris
                        </p>
                        <p className="p18-medium">
                            Registered with the Paris Trade and Companies Register
                            under number 810777888
                        </p>
                        <p className="p18-medium">
                            VAT intra-community number:
                            FR 55 510777888
                        </p>
                        <p className="p18-medium">
                            Email address:
                            <span className="span-blue"> sebastien.potherat@alpahwave.fr</span>
                        </p>
                        <p className="p18-medium">
                            Publication Director:
                            <strong> Sébastien Potherat</strong>
                        </p>
                        <p className="p18-medium">
                            Contact the publication manager:
                            <span className="span-blue"> sebastien.potherat@alpahwave.fr</span>
                        </p>
                    </div>
                </DivLanguage>

            </section>

            <section className="legal-informations-sec3 wave-section">
                <DivLanguage language="fr" className="bg-white">
                    <div className="center">
                        <div className="title">
                            <h2>
                                Hébergement
                            </h2>

                            <p className="p18-medium">
                                Le site est hébergé par Amazon
                                <strong> Web Service 410 Terry Avenue North
                                    WA 98109 Seattle </strong>
                            </p>
                            <p className="p18-medium">
                                CREDITS : les mentions légales ont
                                étés générées par mentions légales
                            </p>
                        </div>
                    </div>
                </DivLanguage>
                <DivLanguage language="en" className="bg-white">
                    <div className="center">
                        <div className="title">
                            <h2>
                                Hosting
                            </h2>

                            <p className="p18-medium">
                                The site is hosted by Amazon
                                <strong> Web Service 410 Terry Avenue North
                                    WA 98109 Seattle </strong>
                            </p>
                            <p className="p18-medium">
                                CREDITS: the legal notice was
                                generated by legal notice
                            </p>
                        </div>
                    </div>
                </DivLanguage>

            </section>
        </>
    )
};

export default LegalInformations;