import React from 'react';
import DivLanguage from "../../components/DivLanguage";

const SectionWawDescription = (props) => {
    return (
        <section className="waw-description-section wave-section">

            <DivLanguage language="fr" className="center">
                <h2>
                    Notre histoire

                </h2>
                <p className="p20-light">
                    Fondée en 2015, Alphawave a investi dans le développement de l'application
                    Osiris afin de répondre à un besoin accru du secteur autour de la transformation
                    et l'organisation des données nécessaires aux différents métiers de l’assurance
                    et de la gestion des risques.
                </p>
                <p className="p20-light">
                    Aujourd'hui, l'application Osiris permet à nos clients de toutes tailles
                    d'absorber de grandes quantités de données internes et/ou externes pour en exploiter
                    tout le potentiel dans l’automatisation de leurs processus internes :
                    comptabilité technique, gestion des risques, provisionnement, tarification,
                    définition d’hypothèses de modélisation...
                </p>
                <p className="p20-light">
                    Nous mettons tout notre engagement dans l'accompagnement de nos clients afin que
                    l'intégration d'Osiris soit un succès.
                    Nous sommes à leur écoute et faisons évoluer Osiris en continu. Nous proposons chaque
                    semestre une nouvelle version de l'application basée sur l'expérience de nos clients
                    et les tendances technologiques.
                </p>
            </DivLanguage>
            <DivLanguage language="en" className="center">
                <h2>
                    Our story
                </h2>
                <p className="p20-light">
                    Founded in 2015, Alphawave invested in the development of the Osiris application
                    to address an increasing need in the industry for the transformation
                    and organization of data necessary for various insurance
                    and risk management professions.
                </p>

                <p className="p20-light">
                    Today, the Osiris application enables our clients of all sizes
                    to absorb large amounts of internal and/or external data to fully exploit
                    its potential in automating their internal processes:
                    technical accounting, risk management, provisioning, pricing,
                    and defining modeling assumptions...
                </p>
                <p className="p20-light">
                    We are fully committed to supporting our clients to ensure the successful
                    integration of Osiris. We are attentive to their needs and continuously improve Osiris.
                    Every semester, we release a new version of the application based on our
                    clients' experiences and technological trends.
                </p>
            </DivLanguage>
        </section>
    )
};

export default SectionWawDescription;