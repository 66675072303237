import React from 'react';
import {Link, NavLink} from "react-router-dom";
import "../style/components/footer.scss";
import LogoAlphawave from "./logo/LogoAlphawave";
import ButtonScrollToTop from "./ButtonScrollToTop";
import DivLanguage from "./DivLanguage";

class Footer extends React.Component {

    constructor() {
        super();
        this.state = {
            year: new Date().getFullYear()
        }
    }

    render() {

        return (
            <footer>
                <div className="bg-left">
                    <div className="bg-right">
                        <div className="center">

                            <div className="scroll-to-top">
                                <ButtonScrollToTop/>
                            </div>

                            <div className="content">

                                <div className="logo">
                                    <LogoAlphawave color="white"/>
                                </div>

                                <div className="right">

                                    <DivLanguage language="fr" className="wrapper-quart">

                                        <div className="col">
                                            <span className="p20-semibold">Nos locaux</span>
                                            <p className="footer-item p16-regular">
                                                8 Rue Blanche <br/>
                                                75009 Paris
                                            </p>
                                        </div>


                                        <div className="col">
                                            <span className="p20-semibold">Nous contacter</span>
                                            <p className="footer-item p16-regular">
                                                01 88 32 71 51
                                            </p>
                                            <p className="footer-item p16-regular">
                                                contact@alphawave.fr
                                            </p>
                                        </div>


                                        <div className="col">
                                            <span className="p20-semibold">Nous suivre</span>


                                            <NavLink to="https://www.linkedin.com/company/alphawave-france/"
                                                     target={"_blank"}
                                                     rel={"noopener noreferrer"}
                                                     className="icon-text footer-item p16-regular">
                                                <i className="fa-brands fa-linkedin"></i>
                                                Alphawave fr
                                            </NavLink>
                                        </div>

                                        <div className="col">
                                            <span className="p20-semibold">Légal</span>
                                            <NavLink className="footer-item p16-regular"
                                                     to="/legal-informations">
                                                Mentions légales
                                            </NavLink>
                                            <NavLink className="footer-item p16-regular"
                                                     to="/privacy-policy">
                                                Politique de confidentialité
                                            </NavLink>
                                        </div>
                                    </DivLanguage>

                                    <DivLanguage language="en" className="wrapper-quart">

                                        <div className="col">
                                            <span className="p20-semibold">Our Offices</span>
                                            <p className="footer-item p16-regular">
                                                8 Rue Blanche <br/>
                                                75009 Paris
                                            </p>
                                        </div>

                                        <div className="col">
                                            <span className="p20-semibold">Contact Us</span>
                                            <p className="footer-item p16-regular">
                                                01 88 32 71 51
                                            </p>
                                            <p className="footer-item p16-regular">
                                                contact@alphawave.fr
                                            </p>
                                        </div>

                                        <div className="col">
                                            <span className="p20-semibold">Follow Us</span>

                                            <NavLink to="https://www.linkedin.com/company/alphawave-france/"
                                                     target={"_blank"}
                                                     rel={"noopener noreferrer"}
                                                     className="icon-text footer-item p16-regular">
                                                <i className="fa-brands fa-linkedin"></i>
                                                Alphawave fr
                                            </NavLink>
                                        </div>

                                        <div className="col">
                                            <span className="p20-semibold">Legal</span>
                                            <NavLink className="footer-item p16-regular"
                                                     to="/legal-informations">
                                                Legal Notice
                                            </NavLink>
                                            <NavLink className="footer-item p16-regular"
                                                     to="/privacy-policy">
                                                Privacy Policy
                                            </NavLink>
                                        </div>
                                    </DivLanguage>


                                    <DivLanguage language="fr" className="copyright p16-regular">
                                        <span>
                                            {
                                                "© " + this.state.year + " Alphawave | " +
                                                "Tous Droits Réservés"
                                            }
                                        </span>
                                    </DivLanguage>
                                    <DivLanguage language="en" className="copyright p16-regular">
                                        <span>
                                            {"© " + this.state.year + " Alphawave | " +
                                                "All Rights Reserved"}
                                        </span>
                                    </DivLanguage>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;