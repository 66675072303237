import React from 'react';
import SectionContact from "../../../components/SectionContact";
import "./cloud-deployment.scss"
import DivLanguage from "../../../components/DivLanguage";
import CardWithBorder from "../../../components/card/CardWithBorder";
import BtnLinkCenter from "../../../components/BtnLinkCenter";
import CardLineChecked from "../../../components/card/CardLineChecked";


const CloudDeploymentPage = () => {

    return (
        <>
            <section className="home-why-osiris">
                <div className="center">
                    <DivLanguage language="fr" className="wrapper-demi">
                        <div className="left">
                            <div className="title-n-description">
                                <h2>
                                    Infrastructure SaaS
                                </h2>
                                <p className="p20-medium">
                                    Avec ce mode de déploiement, le client bénéficie du<b> savoir-faire et de
                                    l'expérience </b>
                                    de notre équipe pour gérer ses environnements Osiris.
                                    L'ouverture du service est instantanée et nécessite peu d'intervention du client.
                                </p>
                                <p className="p20-medium">
                                    Nos environnements sont couplés à des serveurs <b> SFTP </b> pour les clients
                                    désirant
                                    transmettre automatiquement certaines données vers leur environnement Osiris.
                                </p>
                            </div>
                            <CardWithBorder title="Osiris Enterprise SaaS"
                                            icon="fa fa-cloud">

                                <>
                                    <p>
                                        <strong>Pour qui? </strong>
                                    </p>

                                    <p className="p16-regular">
                                        Ce déploiement convient pour les clients de toutes tailles
                                        souhaitant avoir une expérience optimale d'utilisation
                                        grâce à notre connaissance de l'application et de son architecture.
                                    </p>
                                    <p>
                                        <strong>Quel cloud?</strong>
                                    </p>

                                    <p className="p16-regular">
                                        L'application est déployée sur notre cloud
                                        AWS sur un réseau dédié.
                                    </p>
                                    <p>
                                        <strong>A quel coût?</strong>
                                    </p>
                                    <p className="p16-regular">
                                        <li>
                                            Licences annuelles
                                        </li>
                                        <li>
                                            Coût d'infrastructure au forfait ou au réel
                                        </li>
                                    </p>

                                </>

                            </CardWithBorder>
                        </div>
                        <div className="right">
                            <CardWithBorder title="Les services inclus" icon="fa-solid fa-user-circle">
                                <p><CardLineChecked text="Administration de l'application"
                                                    isChecked={true}/></p>
                                <p><CardLineChecked text="Administration des systèmes"
                                                    isChecked={true}/></p>
                                <p><CardLineChecked text="Suivi de la performance et de la disponibilité"
                                                    isChecked={true}/></p>
                                <p><CardLineChecked text="Suivi des menaces"
                                                    isChecked={true}/></p>
                                <p><CardLineChecked text="Support aux utilisateurs"
                                                    isChecked={true}/></p>


                            </CardWithBorder>

                        </div>
                    </DivLanguage>


                    <DivLanguage language="en" className="wrapper-demi">
                        <div className="left">
                            <div className="title-n-description">

                                <h2>
                                    SaaS infrastructure
                                </h2>

                                <p className="p20-medium">
                                    With this deployment mode, the client benefits from the <b> expertise and
                                    experience </b>
                                    of our team to manage their Osiris environments.
                                    The service is opened instantly and requires little intervention from the client.
                                </p>
                                <p className="p20-medium">
                                    Our environments are coupled with <b>SFTP</b> servers for clients wishing to
                                    automatically
                                    transmit certain data to their Osiris environment.
                                </p>
                            </div>
                            <CardWithBorder title="Osiris Enterprise SaaS"
                                            icon="fa fa-cloud">

                                <>
                                    <p>
                                        <strong>For whom?</strong>
                                    </p>

                                    <p className="p16-regular">
                                        This deployment is suitable for clients of all sizes who wish to have an optimal
                                        user experience thanks to our knowledge of the application and its architecture.
                                    </p>
                                    <p>
                                        <strong>Which cloud?</strong>
                                    </p>

                                    <p className="p16-regular">
                                        The application is deployed on our AWS cloud on a dedicated network.
                                    </p>
                                    <p>
                                        <strong>At what cost?</strong>
                                    </p>
                                    <p className="p16-regular">
                                        <li>
                                            Annual licenses
                                        </li>
                                        <li>
                                            Infrastructure cost by package or actual usage
                                        </li>
                                    </p>

                                </>

                            </CardWithBorder>
                        </div>
                        <div className="right">
                            <CardWithBorder title="Included Services" icon="fa-solid fa-user-circle">
                                <p>
                                    <CardLineChecked text="Application administration"
                                                     isChecked={true}/>
                                </p>

                                <p>
                                    <CardLineChecked text="System administration"
                                                     isChecked={true}/>
                                </p>
                                <p>
                                    <CardLineChecked text="Performance and availability monitoring"
                                                     isChecked={true}/>
                                </p>
                                <p>
                                    <CardLineChecked text="Threat monitoring"
                                                     isChecked={true}/>
                                </p>
                                <p>
                                    <CardLineChecked text="User support"
                                                     isChecked={true}/>
                                </p>


                            </CardWithBorder>
                        </div>
                    </DivLanguage>


                    <DivLanguage language="fr" className="wrapper-demi">
                        <BtnLinkCenter link="/osiris"
                                       value="Plus de détails sur Osiris"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>

                    <DivLanguage language="en" className="wrapper-demi">
                        <BtnLinkCenter link="/osiris"
                                       value="More details about Osiris"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>


                </div>
            </section>
            <SectionContact className="white-wave"/>
        </>
    )
};

export default CloudDeploymentPage;