import React from 'react';
import VismeNewsLetterComponent from "../../../components/visme/NewsletterSubscribe";
import SectionContact from "../../../components/SectionContact";
import FeaturesTop from "./FeaturesTop";
import WhatIsOsirisCenter from "./WhatIsOsirisCenter";
import "./whatisosiris.scss";

const WhatIsOsiris = () => {

    return (
        <>
             <FeaturesTop topTitleEN={"What is Osiris?"} topTitleFR={"Qu'est-ce qu'Osiris?"}/>
            <WhatIsOsirisCenter/>
            <SectionContact className="white-wave"/>
        </>
)
};

export default WhatIsOsiris;