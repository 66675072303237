import React from 'react';
import DivLanguage from "../../../components/DivLanguage";
import CardBigIcon from "../../../components/card/CardBigIcon";


const WhatIsOsirisCenter = () => {

    return (
        <>
            <DivLanguage language={"fr"}>
                <section className="whatis-center-section wave-section">

                    <CardBigIcon title={"Plateforme web"}
                                 icon={"fa-solid fa-cloud-arrow-up"}
                    >

                        <p className="p20-light">
                            La solution est déployée sur
                            un<strong> cloud </strong>répondant
                            à vos besoins. Cet espace dédié
                            permet de connecter des nouveaux
                            services ou de gérer en temps réel
                            les ressources de calcul nécessaires.
                        </p>

                        <p className="p20-light">
                            L’application est<strong> accessible </strong>
                            simplement par votre communauté d’utilisateurs
                            facilitant le<strong> partage </strong>de
                            l’information.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title={"Data management"}
                                 icon={"fa-solid fa-database"}>
                        <p className="p20-light">
                            La plateforme vous permettra de gérer
                            l'ensemble du cycle de vos données de
                            la transformation au reporting pour en
                            exploiter le potentiel.
                        </p>

                        <p className="p20-light">
                            Osiris s'appuie sur des techniques
                            de<strong> data science </strong>
                            développées avec les langages R ou Python.
                        </p>

                    </CardBigIcon>
                    <CardBigIcon title={"Assurance"}
                                 icon={"fa-solid fa-umbrella"}>

                        <p className="p20-light">
                            La solution est spécifiquement conçue
                            pour répondre aux besoins
                            des<strong> assureurs </strong>et
                            des<strong> réassureurs</strong>.
                        </p>

                        <p className="p20-light">
                            Elle s’intégre complètement dans
                            les<strong> processus </strong>des métiers
                            du secteur et assure le niveau de
                            <strong> sécurité </strong>requis pour
                            protéger les données et les calculs.
                        </p>
                    </CardBigIcon>
                </section>
            </DivLanguage>

            <DivLanguage language={"en"}>
                <section className="whatis-center-section wave-section">

                    <CardBigIcon title={"Web Platform"}
                                 icon={"fa-solid fa-cloud-arrow-up"}
                    >
                        <p className="p20-light">
                            The solution is deployed on a
                            <strong> cloud </strong>that meets
                            your needs. This dedicated space
                            allows you to connect new services
                            or manage in real-time the necessary
                            computing resources.
                        </p>

                        <p className="p20-light">
                            The application is<strong> easily accessible </strong>
                            by your user community, facilitating the
                            <strong> sharing </strong>of information.
                        </p>
                    </CardBigIcon>
                    <CardBigIcon title={"Data Management"}
                                 icon={"fa-solid fa-database"}>
                        <p className="p20-light">
                            The platform will allow you to manage
                            the entire cycle of your data from
                            transformation to reporting to
                            exploit its potential.
                        </p>

                        <p className="p20-light">
                            Osiris relies on<strong> data science </strong>
                            techniques developed with R or Python languages.
                        </p>

                    </CardBigIcon>
                    <CardBigIcon title={"Insurance"}
                                 icon={"fa-solid fa-umbrella"}>

                        <p className="p20-light">
                            The solution is specifically designed
                            to meet the needs of
                            <strong> insurers </strong>and
                            <strong> reinsurers</strong>.
                        </p>

                        <p className="p20-light">
                            It fully integrates into the
                            <strong> processes </strong>of the industry
                            and ensures the required level of
                            <strong> security </strong>to protect data and calculations.
                        </p>
                    </CardBigIcon>
                </section>
            </DivLanguage>

        </>


)

};

export default WhatIsOsirisCenter;