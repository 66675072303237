import React from 'react';
import Button from "../../components/Button";
import DivLanguage from "../../components/DivLanguage";

const SectionTop = () => {
    return (<section className="home-top-section wave-section">


        <div className="center">
            <DivLanguage language="fr" className="title">
                <h1>
                    Optimisez vos opérations d'assurance grâce
                    à Osiris
                </h1>
                <span className="p20-regular">
                        Alphawave accompagne les acteurs de l'assurance
                        dans les projets d'harmonisation des données et d'automatisation
                        des processus actuariels et financiers.
                      </span>

                <Button value="Découvrir Osiris"
                        link="/osiris/what-is-osiris"/>
            </DivLanguage>
            <DivLanguage language="en" className="title">
                <h1>
                    Optimize your insurance operations with Osiris
                </h1>
                <span className="p20-regular">
                    Alphawave supports insurance industry players in projects for data harmonization
                    and the automation of actuarial and financial processes.
                </span>

                <Button value="Discover Osiris"
                        link="/osiris/what-is-osiris"/>
            </DivLanguage>

        </div>
    </section>)
};

export default SectionTop;