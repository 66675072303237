import React from 'react';
import { useCookies } from 'react-cookie';
import "../style/components/cookies-consent.scss";
import Button from "./Button";
import DivLanguage from "./DivLanguage";

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/" });
    };

    return (
        <div className="center">
            <DivLanguage language="fr" className="cookie-consent">
                <p className="p18-regular">
                    Nous utilisons des cookies pour améliorer
                    votre expérience d'utilisateur.
                    En utilisant notre site web, vous acceptez
                    notre utilisation des cookies.{" "}
                    <a href={"/privacy-policy"}>
                        Lire notre politique de confidentialité
                    </a>
                </p>
                <Button value="Accepter"
                        className="txt-color-dark"
                        onClick={giveCookieConsent}/>
            </DivLanguage>
            <DivLanguage language="en" className="cookie-consent">
                <p className="p18-regular">
                    We use cookies to enhance
                    your user experience.
                    By using our website, you consent
                    to our use of cookies.{" "}
                    <a href={"/privacy-policy"}>
                        Read our privacy policy
                    </a>
                </p>
                <Button value="Accept"
                        className="txt-color-dark"
                        onClick={giveCookieConsent}/>
            </DivLanguage>

        </div>
    );
};

export default CookieConsent;