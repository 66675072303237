import React from 'react';
import SectionContact from "../../../components/SectionContact";
import "./cloud-deployment.scss"
import DivLanguage from "../../../components/DivLanguage";
import CardWithBorder from "../../../components/card/CardWithBorder";
import BtnLinkCenter from "../../../components/BtnLinkCenter";
import CardLineChecked from "../../../components/card/CardLineChecked";


const CloudDeploymentPage = () => {

    return (
        <>
            <section className="home-why-osiris">
                <div className="center">
                    <DivLanguage language="fr" className="wrapper-demi">
                        <div className="left">
                            <div className="title-n-description">
                                <h2>
                                    Infrastructure <i>on premise</i>
                                </h2>
                                <p className="p20-medium">
                                    Avec ce mode de déploiement, les Clients gèrent l'intégralité des opérations de
                                    gestion
                                    de l'application (sécurité, gestion des ressources, ...) sur un des deux opérateurs
                                    de clouds suivants: AWS ou Azure.
                                    L'ouverture du service est soumise à l'adaptation de nos utilitaires d'installation
                                    pour répondre aux besoins du client : cloisonnement des environnements, réplication
                                    des données, branchements à d'autres fournisseurs, ...
                                </p>
                                <p className="p20-medium">
                                    Les environnements peuvent également être couplés à des serveurs <b> SFTP </b>
                                    pour les clients désirant automatiser la transmission de données
                                    vers leurs environnements on-premise d'Osiris.
                                </p>
                            </div>
                            <CardWithBorder title="Osiris Enterprise On Premise"
                                            icon="fa fa-building-o">

                                <>
                                    <p>
                                        <strong>Pour qui? </strong>
                                    </p>

                                    <p className="p16-regular">
                                        Ce mode de déploiement convient aux Clients ayant une expérience avec les
                                        fournisseurs de cloud AWS ou Azure souhaitant héberger eux-mêmes l'application
                                        pour faciliter les connexions avec d'autres applications en amont ou en aval.
                                        Avec le mode On-premise, le client peut optimiser sa consommation cloud en
                                        réservant ou mutualisant de la puissance de calculs avec d'autres applications.
                                    </p>
                                    <p>
                                        <strong>Quel cloud?</strong>
                                    </p>

                                    <p className="p16-regular">
                                        L'application est déployée sur votre cloud Azure et AWS. Nous vous apportons
                                        tout
                                        le support sur les incidents de production.
                                    </p>
                                    <p>
                                        <strong>A quel coût?</strong>
                                    </p>
                                    <p className="p16-regular">
                                        <li>
                                            Licences annuelles
                                        </li>
                                        <li>
                                            Pas d'autres coûts
                                        </li>
                                    </p>

                                </>

                            </CardWithBorder>
                        </div>
                        <div className="right">
                            <CardWithBorder title="Les services inclus" icon="fa-solid fa-user-circle">
                                <p><CardLineChecked text="Administration de l'application"
                                                    isChecked={false}/></p>
                                <p><CardLineChecked text="Administration des systèmes"
                                                    isChecked={false}/></p>
                                <p><CardLineChecked text="Suivi de la performance et de la disponibilité"
                                                    isChecked={false}/></p>
                                <p><CardLineChecked text="Suivi des menaces"
                                                    isChecked={false}/></p>
                                <p><CardLineChecked text="Support aux utilisateurs"
                                                    isChecked={true}/></p>


                            </CardWithBorder>

                        </div>
                    </DivLanguage>

                    <DivLanguage language="en" className="wrapper-demi">
                        <div className="left">
                            <div className="title-n-description"><h2> On-premise Infrastructure </h2> <p
                                className="p20-medium"> With this deployment mode, Clients manage the entire application
                                management operations (security, resource management, ...) on one of two cloud
                                operators: AWS or Azure. The opening of the service is subject to the adaptation of our
                                installation utilities to meet client needs: compartmentalization of environments, data
                                replication, connections to other providers, ... </p> <p className="p20-medium"> The
                                environments can also be coupled with <b> SFTP </b> servers for clients wishing to
                                automate data transmission to their on-premise Osiris environments. </p></div>
                            <CardWithBorder title="Osiris Enterprise On Premise" icon="fa fa-building-o"><> <p><strong>For
                                whom? </strong></p> <p className="p16-regular"> This deployment mode is suitable for
                                Clients who have experience with cloud providers AWS or Azure and wish to host the
                                application themselves to facilitate connections with other upstream or downstream
                                applications. With the On-premise mode, the client can optimize their cloud consumption
                                by reserving or pooling computing power with other applications. </p> <p><strong>Which
                                cloud?</strong></p> <p className="p16-regular"> The application is deployed on your
                                Azure and AWS cloud. We provide full support on production incidents. </p> <p><strong>At
                                what cost?</strong></p>
                                <p className="p16-regular">
                                    <li> Annual licenses</li>
                                    <li> No other costs</li>
                                </p>
                            </>
                            </CardWithBorder></div>
                        <div className="right"><CardWithBorder title="Included services" icon="fa-solid fa-user-circle">
                            <p><CardLineChecked text="Application administration" isChecked={false}/></p>
                            <p>
                                <CardLineChecked text="Systems administration" isChecked={false}/></p>
                            <p><CardLineChecked
                                text="Performance and availability monitoring" isChecked={false}/>
                            </p> <p><CardLineChecked
                            text="Threat monitoring" isChecked={false}/></p>
                            <p>
                                <CardLineChecked text="User support"
                                                 isChecked={true}/>
                            </p>
                        </CardWithBorder></div>
                    </DivLanguage>
                    <DivLanguage language="fr" className="wrapper-demi">
                        <BtnLinkCenter link="/osiris"
                                       value="Plus de détails sur Osiris"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>

                    <DivLanguage language="en" className="wrapper-demi">
                        <BtnLinkCenter link="/osiris"
                                       value="More details about Osiris"
                                       className="big-button txt-color-dark"/>
                    </DivLanguage>


                </div>
            </section>
            <SectionContact className="white-wave"/>
        </>
    )
};

export default CloudDeploymentPage;