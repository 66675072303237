import React from 'react';
import "../../style/components/card-with-border.scss";



const CardWithBorder = ({title="Titre", children="Description un peu longue",
                            icon="fa-regular fa-lemon",
                            className, showButtonClose = false, handleClose}) => {
    return (
        <div className={className + " case"}>
            {showButtonClose && <div className={"header"}>

                <div >

                </div>

                <button
                    className="close-button"
                    onClick={handleClose} // Ajoute une fonction pour gérer la fermeture
                    aria-label="Fermer">
                    <i className="fa fa-times"></i> {/* Icône de fermeture */}
                </button>

            </div>}
            <div className="title-n-icon">
                <i className={icon}/>
                <h3>{title}</h3>


            </div>


            <>
                {children}
            </>
        </div>
    )
};


export default CardWithBorder;