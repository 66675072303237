import * as React from "react";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel"
             hidden={value !== index}
             id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`}
             {...other}>

            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

export default CustomTabPanel;