import React from 'react';
import {NavLink} from "react-router-dom";
import "../style/components/button.scss"

const Button = ({value="Button", link, className, onClick, type, ...inputProps}) => {
    if (link)
        return	(
            <NavLink to={link}>
                <button className={className + " primary-button"}>
                    {value}
                </button>
            </NavLink>);
    if (onClick)
        return (
            <button className={className + " primary-button"}
                    type={type}
                    onClick={onClick}>
                {value}
            </button>
        )
    return (
        <button className={className + " primary-button"}
                type={type} {...inputProps}>
            {value}
        </button>
    )
};

export default Button;