import React from 'react';
import VismeNewsLetterComponent from "../../../components/visme/NewsletterSubscribe";
import SectionContact from "../../../components/SectionContact";
import HowOsirisDoITCenter from "./HowOsirisDoITCenter";
import "./howosirisdoit.scss"
import FeaturesTop from "./FeaturesTop";

const HowOsirisDoIt = () => {

    return (
        <>
            <FeaturesTop topTitleEN={"How does it do?"} topTitleFR={"Comment ça fonctionne?"}/>
            <HowOsirisDoITCenter/>
            <SectionContact className="white-wave"/>
        </>
    )
};

export default HowOsirisDoIt;