import React from 'react';
import "./../../style/forms.scss"
const Input = ({ value, label, required, ...inputProps }) => {


    return (
        <div className="formInput">
            <label className={"formLabelField"}>{label}</label>
            <input className={"formInputField"} value={value} required={required} {...inputProps} />
        </div>
    );
};

export default Input;