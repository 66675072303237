import React from 'react';
import "../style/components/button-scroll-to-top.scss";

const ButtonScrollToTop = (props) => {

    const handleScrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div className="button-scroll-to-top">
            <button onClick={handleScrollToTop}>
                <i className="fa-solid fa-up-long"></i>
            </button>
            <span>Scroll</span>
        </div>
    )
};

export default ButtonScrollToTop;