import React from 'react';
import {useRouteError} from "react-router-dom";

const PageError = (props) => {
    const error = useRouteError()
    return <>
        <h1>{error?.error?.toString() ?? error?.toString()}</h1>
    </>
};

export default PageError;