import React from 'react';
import DivLanguage from "../../components/DivLanguage";

const SectionWawTop = (props) => {
    return (
        <section className="waw-top-section wave-section">
            <div className="center">

                <DivLanguage language="fr" className="title">
                    <h1>
                        Qui sommes-nous ?
                    </h1>
                    <span className="p20-regular">
                        Pour en savoir plus sur ce que nous savons faire et sur notre équipe
                    </span>
                </DivLanguage>
                <DivLanguage language="en" className="title">
                    <h1>
                        Who are we?
                    </h1>
                    <span className="p20-regular">
                        To learn more about what we can do and about our team
                    </span>
                </DivLanguage>

            </div>
        </section>
    )
};

export default SectionWawTop;