import React from 'react';
import "../style/components/contact-section.scss"
import DivLanguage from "./DivLanguage";
import BtnLinkCenter from "./BtnLinkCenter";

const SectionContact = ({className}) => {
    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <section className={className + " home-contact wave-section-top"}>
            <DivLanguage language="fr">
                <h2>Et si on se rencontrait ?</h2>
                <p>
                    Nous pouvons vous présenter notre activité et
                    répondre à vos questions sur nos solutions.
                </p>

                <BtnLinkCenter value={"Nous contacter"}
                               link={"/contact"}/>

            </DivLanguage>
            <DivLanguage language="en">
                <h2>What about a meeting?</h2>
                <p> We can introduce you to our activities and answer your questions about
                    our solutions.
                </p>
                <BtnLinkCenter value={"Contact us"}
                                link={"/contact"}/>

            </DivLanguage>
            <div className="center">

            </div>
        </section>
    )
};

export default SectionContact;