import React, {useEffect, useState} from 'react';
import "../../style/components/header.scss";
import Navigation from "./Navigation";


const Header = () => {

    const [click, setClick] = useState(false);
    const [header, setHeader] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    }



    window.addEventListener('scroll', changeBackground);


    return (
        <header className={header ? 'blue-header active' : 'blue-header'}>

            <Navigation/>
        </header>
    )
};

export default Header;